module.exports = {
  DEVICE_TYPE: "W",
  API_URL: process.env.REACT_APP_BACKEND_API_URL,
  AWS_PROFILE_FOLDER: "profile",
  AWS_WHITE_LABELING_FOLDER: "white_labelling",
  AWS_PRODUCT_FOLDER: "product",
  AWS_CATEGORY_FOLDER: "category",
  AWS_ANNOUNCEMENT_FOLDER: "annoucement",
  AWS_OFFER_FOLDER: "offer",
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  AWS_TEMP_URL_VALIDITY_HOUR: 24,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
  CRYPTO_SECRET_KEY: "ztohxhkzvgxzjxgyzzmg",
  DEFAULT_CURRENCY: "AED",
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  NOTIFICATION_ROLE_LIST: [
    { slug: "all", name: "All" },
    { slug: "freelancers", name: "Freelancer" },
    { slug: "vendor", name: "Sellers" },
    { slug: "buyer", name: "Buyer" },
    { slug: "profile_incomplete", name: "Profile Incomplete" },
  ],
  NOTIFICATION_TYPE: [
    { slug: "push_notification", name: "Push Notification" },
    { slug: "email_notification", name: "Email Notification" },
  ],
  PRIORITY: [
    { slug: "immediate", name: "Immediate" },
    { slug: "scheduled", name: "Scheduled" },
  ],
  OUTLET_TYPES: [
    { slug: "branch", name: "Branch" },
    { slug: "outlet", name: "Outlet" },
  ],
SUBSCRIPTION_STATUS: [
    { id: "active ", name: "Active" },
    { id: "inactive", name: "Inactive" },
  ],
  REPORTED: [
    { slug: "product_comment", name: "Product Comment" },
    { slug: "vendor_comment", name: "Vendor Comment" },
    { slug: "user", name: "User" },
    { slug: "product_review", name: "Product Review" },
    { slug: "vendor_review", name: "Vendor Review" },
    { slug: "message", name: "Message" },
    { slug: "offer_event", name: "Offer Event" },
    { slug: "freelancer_gallery", name: "Freelancer Gallery" },
    { slug: "vendor_gallery", name: "Vendor Gallery" },
    { slug: "product_service", name: "Product Service" },
  ],
  SUBSCRIPTION_TYPES: [
    { slug: "freelancer", name: "Freelancer" },
    { slug: "vendor", name: "Vendor" },
  ],
  SUBSCRIPTION_ANNOUNCEMENT_FREELANCER_ROLE_SLUG: "freelancer",
  ANNOUNCEMENT_ROLE_TYPE: [
    { slug: "freelancer", name: "Freelancer" },
    { slug: "vendor", name: "Vendor" },
    { slug: "buyer", name: "Buyer" },
  ],
  DEVICEWISE_REPORT_TYPE: [
    { slug: "all", name: "All" },
    { slug: "buyer", name: "Buyer" },
    { slug: "vendor", name: "Vendor" },
    { slug: "freelancers", name: "Freelancers" },
  ],
  NOTIFICATION_LOG_TYPE: [
    { slug: "general", name: "General" },
    { slug: "login_alert", name: "Login Alert" },
    { slug: "payment_confirmation", name: "Payment Confirmation" },
    { slug: "payment_success", name: "Payment Success" },
    { slug: "payment_fail", name: "Payment Fail" },
    { slug: "payment_reminder", name: "Payment Reminder" },
    { slug: "add_review", name: "Add Review" },
    { slug: "book_now", name: "Book Now" },
    { slug: "cancel_booking", name: "Cancel Booking" },
    { slug: "freelancer_add_comment", name: "Freelancer Add Comment" },
    { slug: "vendor_add_comment", name: "Vendor Add Comment" },
    { slug: "freelancer_add_review", name: "Freelancer Add Review" },
    { slug: "vendor_add_review", name: "Vendor Add Review" },
    { slug: "promotional", name: "Promotional" },
  ],
  STATUS: [
    { slug: "pending", name: "Pending" },
    { slug: "completed", name: "Completed" },
  ],
  PRODUCT_TYPES: [
    { slug: "product", name: "Product" },
    { slug: "service", name: "Service" },
  ],
  PRODUCT_VISE: { slug: "product", name: "Product" },
  SERVICE_VISE: { slug: "service", name: "Service" },

  STATUS_LIST: [
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
  ],
  OFFER_STATUS_LIST: [
    { id: "upcoming", name: "Upcoming" },
    { id: "running", name: "Running" },
    { id: "expired", name: "Expired" },
  ],
  SPONSERED_LIST: [
    { id: "1", name: "Yes" },
    { id: "0", name: "No" },
  ],
  ROLE_LIST: [
    { slug: "freelancers", name: "Freelancers" },
    { slug: "vendor", name: "Vendor" },
    { slug: "buyer", name: "Buyer" },
  ],
  VENDOR_ROLE_SLUG: "vendor",
  FREELANCER_ROLE_SLUG: "freelancers",
  BUYER_ROLE_SLUG: "buyer",
  LOCALIZATION_TYPES: [
    { slug: "freelancer", name: "Freelancer" },
    { slug: "other", name: "Other" },
  ],
};
