import React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import PublicRoute from "routes/PublicRoute";
import { AppWhileLogo } from "components/_common/Icons";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "utils/DateHelper";

export default function SigninLayout(props) {
  const {t} = useTranslation();
  return (
    <PublicRoute>
      <div className="bg-default main-layout">
        <div className="main-content">
          {/* <!-- Navbar --> */}
          <div>
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
              <div className="container px-4">
                <a className="navbar-brand" href="#">
                  <img alt={"logo"} src={AppWhileLogo} />
                </a>
              </div>
            </nav>
            {/* Header */}
            <div className="header bg-gradient-primary py-7 py-lg-8">
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  x={0}
                  y={0}
                  viewBox="0 0 2560 100"
                  preserveAspectRatio="none"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon
                    className="fill-default"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </div>
            {/* Page content */}
            <div className="container mt--8 pb-5">
              <Outlet />
            </div>
          </div>
          <footer className="py-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="copyright text-center text-white">
                    © {getFormattedDate(new Date(), "Y")}
                    <Link href="#" className="font-weight-bold text-white ml-1">
                      {t("app_name")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </PublicRoute>
  );
}
