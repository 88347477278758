import React from "react";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { getUserAuthToken } from "utils/StorageHelper";
import { mainRoutes as routes } from "config/routing";
const PublicRoute = ({ userInfo, children }) => {
  const navigate = useNavigate();
  const user = getUserAuthToken();
  if (user) {
    // logged in so redirect to dashboard page with the return url
    return <Navigate to={routes.dashboard.path} replace />;
  } else {
    return children;
  }
};
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(PublicRoute);
