import axios from "axios";
import { API_URL } from "config/config";
import { removeLocalStorage } from "utils/StorageHelper";
import { refreshToken } from "store/actions/AuthAction";
import {loginRoutes, mainRoutes} from 'config/routing';
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
});
/**
 * Catch the unAuthorized Request
 */
instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    if(error.response.data.message == "token_expired"){
      // ('Login Session is expired');
      setTimeout(() => {
        removeLocalStorage("userInfo");
        redirectHome(true);
      }, 1000);
    }
    else{
      // call an action
      // refreshToken(function(){
      //   redirectHome(false); 
      // });
      setTimeout(() => {
        removeLocalStorage("userInfo");
        redirectHome(true);
      }, 1000);
    }
    return false;
  }
});
function redirectHome(isLoggedout=false) {
  if(isLoggedout){
    window.location = loginRoutes.signin.path;  
  }
  else{
    window.location = mainRoutes.dashboard.path;
  } 
}
export default instance;