import AppRouteWrapper from './routes/AppRouteWrapper';
import {NotificationContainer} from 'react-notifications';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from 'components/_common/ErrorBoundary/ErrorBoundary';
import { useEffect, useLayoutEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import { routeScrollToTop } from 'utils/CommonHelper';
import 'react-notifications/lib/notifications.css';
import "antd/dist/antd.css";
import 'assets/styles/argon-dashboard.css';
import 'assets/styles/master.css';
import 'assets/styles/developer.css';
import { saveCache } from 'utils/AwsHelper';
const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

// scroll to top on route change
const Wrapper = ({children}) => {
  const location = useLocation();
  const isPop = useBackButton();
  useLayoutEffect(() => {
    // every route change
    routeScrollToTop();
  }, [location.pathname, isPop]);
  return children
} 
function App() {
  useEffect(() => {
    window.addEventListener("beforeunload", saveCache);
    return () => {
      window.removeEventListener("beforeunload", saveCache);
      saveCache();
    };
  }, []);
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <NotificationContainer />
        <Wrapper>
          <AppRouteWrapper/>
        </Wrapper>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
