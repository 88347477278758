import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "utils/DateHelper";
import { mainRoutes as routes } from "config/routing";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid d-none">
        <footer className="footer">
          <div className="row align-items-center justify-content-xl-between">
            <div className="col-12">
              <div className="copyright text-center text-xl-right text-muted">
                © {getFormattedDate(new Date(), "Y")}
                <Link
                  to={routes.dashboard.path}
                  className="font-weight-bold ml-1"
                >
                  {t("app_name")}
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="footerbox">
        <footer className="footer">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-xl-between">
              <div className="col-12">
                <div className="copyright text-center text-md-right text-muted">
                  © {getFormattedDate(new Date(), "Y")}
                  <Link className="font-weight-bold ml-1" to={routes.dashboard.path}>
                    {t("app_name")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
