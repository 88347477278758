import { userInfoSelector } from "components/_selectors/authSelectors";
import { activePageInfoSelector } from "components/_selectors/settingSelectors";
import { loginRoutes } from "config/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "store/actions/AuthAction";
import { getAwsUrl } from "utils/AwsHelper";
import { capitalizeWord } from "utils/CommonHelper";
import { confirmAlert } from "utils/SwalAlertHelper";
import { DefaultProfileImage } from "components/_common/Icons";
import { mainRoutes as routes } from "config/routing";

function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo, activePage } = props;
  const confirmLogout = () => {
    // delete confirmation
    confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes
      logoutUser(() => navigate(loginRoutes.signin.path));
    });
  };
  if (userInfo && Object.keys(userInfo).length > 0) {
    return (
      <nav
        className="navbar navbar-top navbar-expand-md navbar-dark"
        id="navbar-main"
      >
        <div className="container-fluid">
          {/* active page details */}
          <span
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          >
            {activePage?.title}
          </span>
          {/* User */}
          <ul className="navbar-nav align-items-center d-none d-md-flex">
            <li className="nav-item dropdown">
              <a
                className="nav-link pr-0"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      className="profile-img"
                      /* src="/theme/img/theme/team-4-800x800.jpg" */
                      src={(userInfo.photo)? getAwsUrl(userInfo.photo):DefaultProfileImage} alt="Profile"
                    />
                  </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold">
                      {capitalizeWord(
                        userInfo.first_name + " " + userInfo.last_name
                      )}
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                <div className=" dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </div>
                <Link to={routes.updateProfile.path} className="dropdown-item">
                  <i className="ni ni-single-02" />
                  <span>{t("my_profile")}</span>
                </Link>
                <div className="dropdown-divider" />
                <a onClick={() => confirmLogout()} className="dropdown-item">
                  <i className="fas fa-sign-in-alt" />
                  <span>{t("logout")}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    activePage: activePageInfoSelector(state),
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(Header);
