const endpoints = {
  // setting API
  settings: { url: "/settings", method: "GET" },
  // login and profile module
  login: { url: "/login", method: "POST" },
  forgotPassword: { url: "/forgot-password", method: "POST" },
  verifyOTP: { url: "/verification", method: "POST" },
  resetPassword: { url: "/reset-password", method: "POST" },
  logout: { url: "/logout", method: "GET" },
  refreshToken: { url: "/refresh-token", method: "POST" },
  // country
  countryGroupList: { url: "/country-group", method: "POST" },
  countryList: { url: "/country", method: "POST" },
  createCountry: { url: "/create-country", method: "POST" },
  updateCountry: { url: "/update-country", method: "POST" },
  deleteCountry: { url: "/delete-country", method: "POST" },
  // state
  stateList: { url: "/state", method: "POST" },
  createState: { url: "/create-state", method: "POST" },
  updateState: { url: "/update-state", method: "POST" },
  deleteState: { url: "/delete-state", method: "POST" },
  // city
  cityList: { url: "/city", method: "POST" },
  createCity: { url: "/create-city", method: "POST" },
  updateCity: { url: "/update-city", method: "POST" },
  deleteCity: { url: "/delete-city", method: "POST" },
  // area
  areaList: { url: "/area", method: "POST" },
  createArea: { url: "/create-area", method: "POST" },
  updateArea: { url: "/update-area", method: "POST" },
  deleteArea: { url: "/delete-area", method: "POST" },
  // white-labelling
  whiteLabelingList: { url: "/white-labelling", method: "POST" },
  createWhiteLabeling: { url: "/create-white-labelling", method: "POST" },
  updateWhiteLabeling: { url: "/update-white-labelling", method: "POST" },
  deleteWhiteLabeling: { url: "/delete-white-labelling", method: "POST" },
  // announcements
  announcementList: { url: "/announcements", method: "POST" },
  createAnnouncement: { url: "/create-announcement", method: "POST" },
  updateAnnouncement: { url: "/update-announcement", method: "POST" },
  deleteAnnouncement: { url: "/delete-announcement", method: "POST" },
  // Outlet
  outletList: { url: "/outlet-list", method: "POST" },
  createOutlet: { url: "/add-outlet", method: "POST" },
  updateOutlet: { url: "/edit-outlet", method: "POST" },
  deleteOutlet: { url: "/delete-outlet", method: "POST" },
  enableDisableOutlet: { url: "/enable-disable-outlet", method: "POST" },
  // event
  eventList: { url: "/events", method: "POST" },
  addEvent: { url: "/add-event", method: "POST" },
  updateEvent: { url: "/edit-event", method: "POST" },
  deleteEvent: { url: "/event-delete", method: "POST" },
  // product category
  categoryList: { url: "/category", method: "POST" },
  createCategory: { url: "/create-category", method: "POST" },
  updateCategory: { url: "/update-category", method: "POST" },
  deleteCategory: { url: "/delete-category", method: "POST" },
  // product sub category
  subCategoryList: { url: "/subcategory", method: "POST" },
  createSubCategory: { url: "/create-subcategory", method: "POST" },
  updateSubCategory: { url: "/update-subcategory", method: "POST" },
  deleteSubCategory: { url: "/delete-subcategory", method: "POST" },
  // product
  productList: { url: "/product-list", method: "POST" },
  productInfo: { url: "/product-info", method: "POST" },
  createProduct: { url: "/add-product", method: "POST" },
  updateProduct: { url: "/edit-product", method: "POST" },
  deleteProduct: { url: "/delete-product", method: "POST" },
  productCommentList: { url: "/product-review-comment-list", method: "POST" },
  productReviewList: { url: "/product-review", method: "POST" },
  // dashboard
  dashboardData: { url: "/dashboard", method: "POST" },
  deviceWiseReportData: { url: "/devicewise-report", method: "POST" },
  deviceVersionWiseReportData: {
    url: "/deviceversionwise-report",
    method: "POST",
  },
  dateWiseUserReportData: {
    url: "/datewise-registration-report",
    method: "POST",
  },
  dateWiseDeviceInstallData: { url: "/get-app-install", method: "POST" },
  // profile
  getProfileDetails: { url: "/edit-profile", method: "POST" },
  updateUser: { url: "/update-user", method: "POST" },
  // subscription
  subscriptionList: { url: "/subscription", method: "POST" },
  createSubscription: { url: "/create-subscription", method: "POST" },
  updateSubscription: { url: "/update-subscription", method: "POST" },
  deleteSubscription: { url: "/delete-subscription", method: "POST" },
  // freelancer banners
  freelancerBannerList: { url: "/bannerlist", method: "POST" },
  addFreelancerBanner: { url: "/add-banner", method: "POST" },
  updateFreelancerBanner: { url: "/edit-banner", method: "POST" },
  deleteFreelancerBanner: { url: "/banner-delete", method: "POST" },
  // transcations
  transactionList: { url: "/subscription-transaction", method: "POST" },
  // User
  userList: { url: "/userlist", method: "POST" },
  userInfo: { url: "/user-info", method: "POST" },
  deleteUser: { url: "/delete-user-account", method: "POST" },
  unBlockUser: { url: "/unblock-user", method: "POST" },
  manageVendorStatus: { url: "/manage-user-status", method: "POST" },
  userReviewList: { url: "/user-review-list", method: "POST" },
  userCommentList: { url: "/user-review-comment-list", method: "POST" },
  // word filter
  wordFilterList: { url: "/word-value-list", method: "POST" },
  createWordFilter: { url: "/store-word-value", method: "POST" },
  updateWordFilter: { url: "/update-word-value", method: "POST" },
  deleteWordFilter: { url: "/delete-word-value", method: "POST" },
  bulkImportWordFilter: {
    url: "/store-word-csv-value",
    method: "POSTFORMDATA",
  },
  //domain black-list
  domainList: { url: "/domain-blacklist", method: "POST" },
  addDomainList: { url: "/create-domain-blacklist", method: "POST" },
  deleteDomainData: { url: "/delete-domain-blacklist", method: "POST" },
  verify2FACode: { url: "/verify-two-factor-code", method: "POST" },
  resend2FACode: { url: "/resend-two-factor-code", method: "POST" },
  //push email notification
  pushemailnotificationlist: {
    url: "/push-email-notification-list",
    method: "POST",
  },
  sendEmailNotification: {
    url: "/send-push-email-notification",
    method: "POST",
  },
  //notification log
  notificationLoglist: { url: "/notification-log", method: "POST" },
  //apilog
  apiLoglist: { url: "/api-logs", method: "POST" },
  apiLogInfo: { url: "/api-log-info", method: "POST" },
  //reported things
  reportedthingslist: { url: "/report-content-list", method: "POST" },
  deleteReportedData: { url: "/delete-report-content", method: "POST" },
};
export default endpoints;
