import store from "store";
import APIService from "services/api.service";
import api from "config/api";
import { successMessage, errorMessage } from "utils/ToastNotificationHelper";
import {
  getUserInfo,
  storeUserInfo,
  removeLocalStorage,
} from "utils/StorageHelper";
import { useTranslation } from "react-i18next";
export const getListData = async (apiurl, method, data, callback) => {
  // const response = await APIService.callAPI(apiurl, method, data);
  // if(response){
  //   callback(response.status,response);
  // }
  const response = await APIService.getData(apiurl, data);
  if (response) {
    callback(response.status, response);
  }
};
export const manageAPICall = async (apiurl, method, data, callback) => {
  var response;
  if (method == "GET") {
    response = await APIService.getData(apiurl, data);
  } else if (method == "POST") {
    response = await APIService.submitData(apiurl, data);
  } else if (method == "PATCH") {
    response = await APIService.submitPatchData(apiurl, data);
  } else if (method == "DELETE") {
    response = await APIService.deleteData(apiurl, data);
  } else if (method == "POSTFORMDATA") {
    response = await APIService.submitFormData(apiurl, data);
  }
  if (response) {
    if (callback) {
      callback(response.status, response);
    } else {
      return response;
    }
  }
};
export const countryGroupList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.countryGroupList.url,
    api.countryGroupList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const countryList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.countryList.url,
    api.countryList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const stateList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.stateList.url,
    api.stateList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const cityList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.cityList.url,
    api.cityList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const categoryList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(
    api.categoryList.url,
    api.categoryList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const subCategoryList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(
    api.subCategoryList.url,
    api.subCategoryList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const userList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(
    api.userList.url,
    api.userList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
