import AWS from "aws-sdk";
import LRUCache from "lru-cache";
import {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_REGION,
  AWS_TEMP_URL_VALIDITY_HOUR,
  AWS_BUCKET,
} from "config/config";
import config from "config/config";
AWS.config.update({
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
  region: AWS_REGION,
});

const rootPath = "public/upload";
const s3 = new AWS.S3();
const myBucket = AWS_BUCKET;
const signedUrlExpireSeconds = 3600 * AWS_TEMP_URL_VALIDITY_HOUR;
const cache = new LRUCache({
  ttl: 3600 * (AWS_TEMP_URL_VALIDITY_HOUR - 1), // Maximum age of items (in milliseconds)
  ttlAutopurge: true,
});
export const loadCache = () => {
  const data = localStorage.getItem("imageCache");
  if (data) {
    cache.load(JSON.parse(data));
  }
};
export const saveCache = () => {
  localStorage.setItem("imageCache", JSON.stringify(cache.dump()));
};
export const getAwsUrl = (keyFile) => {
  const cachedUrl = cache.get(keyFile);
  if (cachedUrl) {
    // retrieved from cache
    return cachedUrl;
  }
  const url = s3.getSignedUrl("getObject", {
    Bucket: myBucket,
    Key: keyFile,
    Expires: signedUrlExpireSeconds,
  });
  // setting cache
  cache.set(keyFile, url);
  return url;
};
export const uploadAwsFile = async (type, file) => {
  var folder = "images";
  switch (type) {
    case "while_labeling":
      folder = config.AWS_WHITE_LABELING_FOLDER;
      break;
    case "product":
      folder = config.AWS_PRODUCT_FOLDER;
      break;
    case "category":
      folder = config.AWS_CATEGORY_FOLDER;
      break;
    case "annoucement": 
      folder = config.AWS_ANNOUNCEMENT_FOLDER;
      break;
    case "profile":
      folder = config.AWS_PROFILE_FOLDER;
      break;
    case "offer":
      folder = config.AWS_OFFER_FOLDER;
      break;
    default:
      folder = "images";
  }
  const folderName = rootPath + "/" + folder;
  const fileName = file.name;
  const keyName = `${folderName}/${fileName}`;
  const params = {
    Bucket: myBucket,
    Key: keyName,
    Body: file,
  };
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
        return;
      }
      resolve(data.Key);
    });
  });
};
