import React, { lazy } from "react";
// signin pages
const LoginPage = lazy(() => import("pages/Login"));
const ForgotPasswordPage = lazy(() => import("pages/Login/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("pages/Login/ResetPassword"));
// admin routes
const DashboardPage = lazy(() => import("pages/Dashboard"));
// event
const EventPage = lazy(() => import("pages/Event"));
const ViewEventPage = lazy(() => import("pages/Event/ViewEvent"));
// freelancer banner
const FreelancerBannerPage = lazy(() => import("pages/FreelancerBanner"));
const ViewFreelancerBannerPage = lazy(() =>
  import("pages/FreelancerBanner/ViewFreelancerBanner")
);
// localization
const LocalizationPage = lazy(() => import("pages/Localization"));
const CountryPage = lazy(() => import("pages/Localization/Country"));
const StatePage = lazy(() => import("pages/Localization/State"));
const CityPage = lazy(() => import("pages/Localization/City"));
const AreaPage = lazy(() => import("pages/Localization/Area"));
// white labeling
const WhiteLabelingPage = lazy(() => import("pages/WhiteLabeling"));
// announcements
const AnnouncementPage = lazy(() => import("pages/Announcement"));
// Outlet
const OutletPage = lazy(() => import("pages/Outlet"));
// const AddOutletPage = lazy(() => import("pages/Outlet/AddOutlet"));
const ViewOutletPage = lazy(() => import("pages/Outlet/ViewOutlet"));
// products
const MainProductPage = lazy(() => import("pages/Product"));
const CategoryPage = lazy(() => import("pages/Product/Category"));
const SubCategoryPage = lazy(() => import("pages/Product/SubCategory"));
const ProductListPage = lazy(() => import("pages/Product/ProductList"));
const AddProductPage = lazy(() =>
  import("pages/Product/ProductList/AddProduct")
);
const EditProductPage = lazy(() =>
  import("pages/Product/ProductList/EditProduct")
);
const ServiceListPage = lazy(() => import("pages/Product/ServiceList"));
const AddServicePage = lazy(() =>
  import("pages/Product/ServiceList/AddService")
);
const EditServicePage = lazy(() =>
  import("pages/Product/ServiceList/EditService")
);
// subscriptions
const SubscriptionListPage = lazy(() => import("pages/Subscription"));
const AddSubscriptionPage = lazy(() =>
  import("pages/Subscription/AddSubscription")
);
const EditSubscriptionPage = lazy(() =>
  import("pages/Subscription/EditSubscription")
);
// transactions
const TransactionListPage = lazy(() => import("pages/Transaction"));
// users
const UserListPage = lazy(() => import("pages/User"));
const ViewUserPage = lazy(() => import("pages/User/ViewUser"));
// vendors
const MainVendorPage = lazy(() => import("pages/Vendor"));
const VendorCategoryPage = lazy(() => import("pages/Vendor/Category"));
const VendorSubCategoryPage = lazy(() => import("pages/Vendor/SubCategory"));
const VendorListPage = lazy(() => import("pages/Vendor/VendorList"));
const ViewVendorPage = lazy(() => import("pages/Vendor/ViewVendor"));
// freelancer
const MainFreelancerPage = lazy(() => import("pages/Freelancer"));
const FreelancerCategoryPage = lazy(() => import("pages/Freelancer/Category"));
const FreelancerSubCategoryPage = lazy(() =>
  import("pages/Freelancer/SubCategory")
);
const FreelancerListPage = lazy(() =>
  import("pages/Freelancer/FreelancerList")
);
const ViewFreelancerPage = lazy(() =>
  import("pages/Freelancer/ViewFreelancer")
);
//update profile
const UpdateProfilePage = lazy(() => import("pages/Profile/UpdateProfile"));
// word filters
const WordFilterPage = lazy(() => import("pages/WordFilter"));
// reports
const ReportPage = lazy(() => import("pages/Report"));
// domainblacklist
const DomainBlackListPage = lazy(() => import("pages/DomainBlackList"));
const AddDomainBlackListPagePage = lazy(() =>
  import("pages/DomainBlackList/AddDomainBlackList")
);
//notification
const NotificationPage = lazy(() => import("pages/Notification"));
const SendNotificationPage = lazy(() => import("pages/Notification/SendNotification"));
//notification log
const NotificationLogPage = lazy(() => import("pages/NotificationLog"));
//api log
const ApiLogPage = lazy(() => import("pages/ApiLogs"));
//reportedthings
const ReportedThingsPage = lazy(() => import("pages/ReportedThings"));
export const localizationRoutes = {
  countryList: { path: "country", element: <CountryPage /> },
  stateList: { path: "state", element: <StatePage /> },
  cityList: { path: "city", element: <CityPage /> },
  areaList: { path: "area", element: <AreaPage /> },
};
export const productRoutes = {
  categoryList: { path: "category", element: <CategoryPage /> },
  subCategoryList: { path: "sub-category", element: <SubCategoryPage /> },
  productList: { path: "product-list", element: <ProductListPage /> },
  addProduct: { path: "product-list/add", element: <AddProductPage /> },
  editProduct: { path: "product-list/edit/:id", element: <EditProductPage /> },
  serviceList: { path: "service-list", element: <ServiceListPage /> },
  addService: { path: "service-list/add", element: <AddServicePage /> },
  editService: { path: "service-list/edit/:id", element: <EditServicePage /> },
};
export const vendorRoutes = {
  categoryList: { path: "category", element: <VendorCategoryPage /> },
  subCategoryList: { path: "sub-category", element: <VendorSubCategoryPage /> },
  vendorList: { path: "vendor-list", element: <VendorListPage /> },
  vendorDetail: {
    path: "vendor-list/details/:id",
    element: <ViewVendorPage />,
  },
};
export const freelancerRoutes = {
  categoryList: { path: "category", element: <FreelancerCategoryPage /> },
  subCategoryList: {
    path: "sub-category",
    element: <FreelancerSubCategoryPage />,
  },
  freelancerList: { path: "freelancer-list", element: <FreelancerListPage /> },
  freelancerDetail: {
    path: "freelancer-list/details/:id",
    element: <ViewFreelancerPage />,
  },
};
export const mainRoutes = {
  dashboard: { path: "/", element: <DashboardPage /> },
  events: { path: "/events", element: <EventPage /> },
  viewEvent: { path: "/events/details/:id", element: <ViewEventPage /> },
  freelancerBanner: {
    path: "/freelancer-bannners",
    element: <FreelancerBannerPage />,
  },
  viewFreelancerBanner: {
    path: "/freelancer-bannners/details/:id",
    element: <ViewFreelancerBannerPage />,
  },
  whiteLabeling: { path: "/white-labeling", element: <WhiteLabelingPage /> },
  announcements: { path: "/announcements", element: <AnnouncementPage /> },
  outlets: { path: "/outlets", element: <OutletPage /> },
  // addOutlet: { path: "/outlet/add", element: <AddOutletPage /> },
  viewOutlet: { path: "/outlets/details/:id", element: <ViewOutletPage /> },
  wordFilter: { path: "/word-filters", element: <WordFilterPage /> },
  reports: { path: "/reports", element: <ReportPage /> },
  // domainblacklist
  domainBlackList: {
    path: "/domain-blacklist",
    element: <DomainBlackListPage />,
  },
  addDomainBlackList: {
    path: "/domain-blacklist/add",
    element: <AddDomainBlackListPagePage />,
  },
  //notification
  notificationPage: { path: "/notification", element: <NotificationPage /> },
  sendnotificationPage: { path: "/send-notification", element: <SendNotificationPage /> },
  //notification
  notificationLogPage: { path: "/notification-log", element: <NotificationLogPage /> },
  //notification
  apiLogPage: { path: "/api-log", element: <ApiLogPage /> },
  //reportedthings
  reportedThingsPage: { path: "/reported-things", element: <ReportedThingsPage /> },
  localization: {
    path: "/localization",
    element: <LocalizationPage />,
    routes: localizationRoutes,
  },
  products: {
    path: "/products",
    element: <MainProductPage />,
    routes: productRoutes,
  },
  vendors: {
    path: "/vendors",
    element: <MainVendorPage />,
    routes: vendorRoutes,
  },
  freelancers: {
    path: "/freelancers",
    element: <MainFreelancerPage />,
    routes: freelancerRoutes,
  },
  // subscriptions
  subscriptions: { path: "/subscriptions", element: <SubscriptionListPage /> },
  addSubscription: {
    path: "/subscriptions/add",
    element: <AddSubscriptionPage />,
  },
  editSubscription: {
    path: "/subscriptions/edit/:id",
    element: <EditSubscriptionPage />,
  },
  // transactions
  transactions: { path: "/transactions", element: <TransactionListPage /> },
  // users
  users: { path: "/buyers", element: <UserListPage /> },
  viewUser: { path: "/buyers/details/:id", element: <ViewUserPage /> },
  // profile
  updateProfile: { path: "/update-profile", element: <UpdateProfilePage /> },
};

export const loginRoutes = {
  signin: { path: "/signin", element: <LoginPage /> },
  forgotPassword: { path: "/forgot-password", element: <ForgotPasswordPage /> },
  resetPassword: { path: "/reset-password", element: <ResetPasswordPage /> },
};
