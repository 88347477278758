import React from "react";
import {
  mainRoutes as routes,
  loginRoutes,
  localizationRoutes,
} from "config/routing";
import { Link, useLocation } from "react-router-dom";
import { checkActiveRoute } from "utils/CommonHelper";
import { useTranslation } from "react-i18next";
import { productRoutes } from "config/routing";
import { vendorRoutes } from "config/routing";
import { freelancerRoutes } from "config/routing";

export default function NavbarLinks() {
  const location = useLocation();
  const { t } = useTranslation();
  let pathname = location.pathname;
  return (
    <>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            to={routes.dashboard.path}
            className={`nav-link 
                        ${checkActiveRoute(
                          pathname,
                          [routes.dashboard.path],
                          false,
                          true
                        )}
                      `}
          >
            <i className="ni ni-tv-2 text-orange" /> {t("dashboard")}
          </Link>
        </li>
        <li
          className={`nav-item ${checkActiveRoute(
            pathname,
            [routes.localization.path],
            true
          )}`}
        >
          <a
            href="#"
            className="nav-link"
            data-toggle="collapse"
            data-target="#localization-menu-content"
            aria-expanded={
              checkActiveRoute(pathname, [routes.localization.path], true) != ""
                ? true
                : false
            }
          >
            <i className="ni ni-pin-3 text-orange"></i> {t("localization")}
          </a>
          <ul
            id="localization-menu-content"
            /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
              pathname,
              [routes.localization.path],
              true
            )}`}
          >
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          localizationRoutes.countryList.path,
                        ])}
                      `}
                to={
                  routes.localization.path +
                  "/" +
                  localizationRoutes.countryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("country")}
              </Link>
            </li>
            {/* <li className="">
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          localizationRoutes.stateList.path,
                        ])}
                      `}
                to={
                  routes.localization.path +
                  "/" +
                  localizationRoutes.stateList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("state")}
              </Link>
            </li> */}
            <li className="">
              <Link
                className={`dropdown-item 
                      ${checkActiveRoute(pathname, [
                        localizationRoutes.cityList.path,
                      ])}
                    `}
                to={
                  routes.localization.path +
                  "/" +
                  localizationRoutes.cityList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("city")}
              </Link>
            </li>
            <li className="d-none">
              <Link
                className={`dropdown-item 
                      ${checkActiveRoute(pathname, [
                        localizationRoutes.areaList.path,
                      ])}
                    `}
                to={
                  routes.localization.path +
                  "/" +
                  localizationRoutes.areaList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("area")}
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [
                          routes.whiteLabeling.path,
                        ])}
                      `}
            to={routes.whiteLabeling.path}
          >
            <i className="ni ni-palette text-orange" /> {t("white_labeling")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [
                          routes.announcements.path,
                        ])}
                      `}
            to={routes.announcements.path}
          >
            <i className="ni ni-notification-70 text-orange" /> {t("announcements")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.outlets.path])}
                      `}
            to={routes.outlets.path}
          >
            <i className="ni ni-shop text-orange" /> {t("outlet")}
          </Link>
        </li>
        {/*<li className="nav-item">
              <Link
                className={`nav-link 
                        ${checkActiveRoute(pathname, [localizationRoutes.stateList.path])}
                      `}
                to={
                  routes.localization.path +
                  "/" +
                  localizationRoutes.stateList.path
                }
              >
                <i className="ni ni-pin-3 text-orange" /> {t("state")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link 
                        ${checkActiveRoute(pathname, [localizationRoutes.cityList.path])}
                      `}
                to={
                  routes.localization.path +
                  "/" +
                  localizationRoutes.cityList.path
                }
              >
                <i className="ni ni-pin-3 text-orange" /> {t("city")}
              </Link>
            </li> */}
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.events.path])}
                      `}
            to={routes.events.path}
          >
            <i className="ni ni-tag text-orange" /> {t("events")}
          </Link>
        </li>
        <li
          className={`nav-item ${checkActiveRoute(
            pathname,
            [routes.products.path],
            true
          )}`}
        >
          <a
            href="#"
            className="nav-link"
            data-toggle="collapse"
            data-target="#product-menu-content"
            aria-expanded={
              checkActiveRoute(pathname, [routes.products.path], true) != ""
                ? true
                : false
            }
          >
            <i className="ni ni-box-2 text-orange"></i> {t("products")}
          </a>
          <ul
            id="product-menu-content"
            /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
              pathname,
              [routes.products.path],
              true
            )}`}
          >
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.products.path +
                  "/" +
                  productRoutes.categoryList.path],false, true
                        )}
                      `}
                to={
                  routes.products.path +
                  "/" +
                  productRoutes.categoryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("category")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.products.path +
                  "/" +
                  productRoutes.subCategoryList.path],false, true
                        )}
                      `}
                to={
                  routes.products.path +
                  "/" +
                  productRoutes.subCategoryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("sub_category")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.products.path +
                  "/" +
                  productRoutes.productList.path],false, true
                        )}
                      `}
                to={
                  routes.products.path +
                  "/" +
                  productRoutes.productList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("product_list")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.products.path +
                  "/" +
                  productRoutes.serviceList.path],false, true
                        )}
                      `}
                to={
                  routes.products.path +
                  "/" +
                  productRoutes.serviceList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("service_list")}
              </Link>
            </li>
          </ul>
        </li>
        {/* // vendors  */}
        <li
          className={`nav-item ${checkActiveRoute(
            pathname,
            [routes.vendors.path],
            true
          )}`}
        >
          <a
            href="#"
            className="nav-link"
            data-toggle="collapse"
            data-target="#vendor-menu-content"
            aria-expanded={
              checkActiveRoute(pathname, [routes.vendors.path], true) != ""
                ? true
                : false
            }
          >
            <i className="fa fa-user-friends text-orange"></i> {t("vendors")}
          </a>
          <ul
            id="vendor-menu-content"
            /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
              pathname,
              [routes.vendors.path],
              true
            )}`}
          >
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.vendors.path +
                  "/" +
                  vendorRoutes.categoryList.path],false, true
                        )}
                      `}
                to={
                  routes.vendors.path +
                  "/" +
                  vendorRoutes.categoryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("category")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.vendors.path +
                  "/" +
                  vendorRoutes.subCategoryList.path],false, true
                        )}
                      `}
                to={
                  routes.vendors.path +
                  "/" +
                  vendorRoutes.subCategoryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("sub_category")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.vendors.path +
                  "/" +
                  vendorRoutes.vendorList.path],false, true
                        )}
                      `}
                to={
                  routes.vendors.path +
                  "/" +
                  vendorRoutes.vendorList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("vendor_list")}
              </Link>
            </li>
          </ul>
        </li>
        {/* // freelancer  */}
        <li
          className={`nav-item ${checkActiveRoute(
            pathname,
            [routes.freelancers.path],
            true
          )}`}
        >
          <a
            href="#"
            className="nav-link"
            data-toggle="collapse"
            data-target="#freelancer-menu-content"
            aria-expanded={
              checkActiveRoute(pathname, [routes.freelancers.path], true) != ""
                ? true
                : false
            }
          >
            <i className="fa fa-user-friends text-orange"></i> {t("freelancers")}
          </a>
          <ul
            id="freelancer-menu-content"
            /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
              pathname,
              [routes.freelancers.path],
              true
            )}`}
          >
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.freelancers.path +
                  "/" +
                  freelancerRoutes.categoryList.path],false, true
                        )}
                      `}
                to={
                  routes.freelancers.path +
                  "/" +
                  freelancerRoutes.categoryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("category")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          routes.freelancers.path +
                  "/" +
                  freelancerRoutes.subCategoryList.path],false, true
                        )}
                      `}
                to={
                  routes.freelancers.path +
                  "/" +
                  freelancerRoutes.subCategoryList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("sub_category")}
              </Link>
            </li>
            <li>
              <Link
                className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          freelancerRoutes.freelancerList.path],false, true
                        )}
                      `}
                to={
                  routes.freelancers.path +
                  "/" +
                  freelancerRoutes.freelancerList.path
                }
              >
                <span>
                  <i className="ni ni-bold-right"></i>
                </span>
                {t("freelancer_list")}
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.users.path])}
                      `}
            to={routes.users.path}
          >
            <i className="fa fa-user-friends text-orange" /> {t("buyers")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.subscriptions.path])}
                      `}
            to={routes.subscriptions.path}
          >
            <i className="ni ni-money-coins text-orange" /> {t("subscriptions")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.freelancerBanner.path])}
                      `}
            to={routes.freelancerBanner.path}
          >
            <i className="ni ni-image text-orange" /> {t("freelancer_banners")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.transactions.path])}
                      `}
            to={routes.transactions.path}
          >
            <i className="fa fa-money-check-alt text-orange" /> {t("transactions")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.wordFilter.path])}
                      `}
            to={routes.wordFilter.path}
          >
            <i className="fa fa-filter text-orange" /> {t("word_filters")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.reports.path])}
                      `}
            to={routes.reports.path}
          >
            <i className="ni ni-folder-17 text-orange" /> {t("reports")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.domainBlackList.path])}
                      `}
            to={routes.domainBlackList.path}
          >
            <i className="fa fa-server text-orange" /> {t("domain_black_list")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.notificationPage.path])}
                      `}
            to={routes.notificationPage.path}
          >
            <i className="fa fa-bell text-orange" /> {t("notification")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.notificationLogPage.path])}
                      `}
            to={routes.notificationLogPage.path}
          >
            <i className="fa fa-bell text-orange" /> {t("notification_log")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.apiLogPage.path])}
                      `}
            to={routes.apiLogPage.path}
          >
            <i className="fa fa-bell text-orange" /> {t("api_logs")}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link 
                        ${checkActiveRoute(pathname, [routes.reportedThingsPage.path])}
                      `}
            to={routes.reportedThingsPage.path}
          >
            <i className="ni ni-folder-17 text-orange" /> {t("reported_things")}
          </Link>
        </li>
      </ul>
      {/* Divider */}
      {/* <hr className="my-3" /> */}
    </>
  );
}
