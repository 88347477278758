import moment from 'moment';
import store from 'store';
import { settingsSelector } from 'components/_selectors/authSelectors';

export const getCurrentDateTime = () => {
  return moment();
}
export const getFormattedDate = (date, dateFormat="") => {
  // if(settingsSelector(store.getState()).date_format_web){
  //   dateFormat = settingsSelector(store.getState()).date_format_web;
  //   dateFormat = dateFormat.split(" ")[0];
  // }
  if(dateFormat==""){
    dateFormat='DD/MM/YYYY'
  }
  return moment(date).format(dateFormat);
}
export const getFormattedTime = (dateTime, timeFormat='h:mm A') => {
  //  var date = moment().format('YYYY-MM-DD')+" "+time;
  return moment(dateTime).format(timeFormat);
}
export const getFormattedDateTime = (date, dateTimeFormat="") => {
  if(dateTimeFormat==""){
    dateTimeFormat='DD/MM/YYYY h:mm A'
  }
  return moment(date).format(dateTimeFormat);
}
export const getFormattedDateTimeInYMDHMS = (date, dateTimeFormat = "YYYY-MM-DD HH:MM:SS") => {
  return moment(date).format(dateTimeFormat);
};
export const getServerFormattedDate = (date) => {
  var dateFormat = "YYYY-MM-DD";
  return moment(date).format(dateFormat);
};
export const getServerFormattedTime = (time) => {
  var timeFormat = "HH:MM";
  return moment(time).format(timeFormat);
};
export const getUtcTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const localTime = moment().set({ hours, minutes });
  const utcTime = localTime.utc();
  return utcTime.format('HH:mm');
};

export const convertUTCtoLocalDateTime = (utcDateTime) => {
  const dateObject = new Date(utcDateTime);
  const localDateTime = new Date(dateObject.getTime() + (dateObject.getTimezoneOffset() * 60000));
  
  const yyyy = localDateTime.getFullYear();
  const mm = String(localDateTime.getMonth() + 1).padStart(2, '0');
  const dd = String(localDateTime.getDate()).padStart(2, '0');
  const hh = String(localDateTime.getHours()).padStart(2, '0');
  const min = String(localDateTime.getMinutes()).padStart(2, '0');
  
  return `${yyyy}-${mm}-${dd} ${hh}:${min}`;
};

export const getUTCDate = (dateTime) => {
  // Append the time to your date string
  var dateTimeString = dateTime; // Assuming you want to append "11:04" as the time
  var inputFormat = "YYYY-MM-DD HH:mm"; // Adjust this format according to your input date time

  // Format the date time string according to the input format
  var formattedDateTime = moment(dateTimeString, inputFormat);

  // Convert the formatted date time to UTC
  var utcDateTime = formattedDateTime.utc();

  // Define your desired output format
  var outputFormat = "YYYY-MM-DD HH:mm"; // Customize this according to your needs

  // Format the UTC date time according to the output format
  var formattedUTCDateTime = utcDateTime.format(outputFormat);

  // Return the formatted UTC date time
  return formattedUTCDateTime;
};
