import { t } from "i18next";
import Swal from "sweetalert2";
export const confirmAlert = (msg, callback) => {
    Swal.fire({
        title: t("confirm_title"),
        text: msg,
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: t("yes"),
        cancelButtonText: t("no"),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1'
        },
        buttonsStyling: !1,
      }).then(function (result) {
        if (result.isConfirmed) {
          if(callback){
              callback();
          }
        }
      });
}